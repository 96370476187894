html {
	box-sizing: border-box;
	font-size: 18px;
	line-height: 1.5;
	-webkit-tap-highlight-color: transparent;
}

body {
	background: #fff;
	color: #3b4351;
	font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,sans-serif;
	font-size: 14px;
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
}

.h5, h5 {
	font-size: 1.3rem;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: 500;
}

.text-muted {
	color: #b4b8bd;
}

.btn {
	font-size: 16px;
	border-radius: 2px;
	padding: .25rem .4rem;
}

.btn-primary {
	color: #fff;
	border-color: #e78035;
	background: #e78035;
}

.btn-primary:hover, 
.btn-primary:visited, 
.btn-primary:focus, 
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle,
.btn-primary.disabled, 
.btn-primary:disabled {
	background: #cf7f45;
	border-color: #cf7f45;
}

.btn-outline-primary,
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
	background: #fff;
	color: #e78035;
	border-color: #e78035;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
	color: #fff;
	background: #e78035;
	border-color: #e78035;
}

a, a:visited, .text-primary {
	color: #e78035 !important;
}

a:hover, a:focus, a:active,
.text-primary:hover, .text-primary:focus, .text-primary:active {
	color: #cf7f45 !important;
}

label.form-label {
	margin-bottom: 3px;
	font-weight: 500;
}

.full-width {
	width: 100%;
}

.nav-toggle-icon {
	font-size: 18px;
	cursor: pointer;
	display: inline-block;
	position: relative;
	top: 2px;
}

.text-link {
	color: #e78035;
	cursor: pointer;
}

.text-link:hover, .text-link:focus, .text-link:active {
	color:#cf7f45;
}

.card-title {
	color: #3b4351;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 3px;
}

.card-subtitle {
	color: #949aa2;
	font-size: 16px;
}

.card {
	border: none;
	border-radius: 0px;
	-webkit-box-shadow: 0 0.25rem 1rem rgba(48,55,66,.15);
	box-shadow: 0 0.25rem 1rem rgba(48,55,66,.15);
	margin-bottom: 20px;
}

.card-body {
	padding: .8rem;
}

.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: #fff;
	background-image: none;
	border: .05rem solid #bcc3ce;
	border-radius: .1rem;
	color: #3b4351;
	display: block;
	font-size: 0.9rem;
	height: 2rem;
	line-height: 1.2rem;
	max-width: 100%;
	outline: none;
	padding: .25rem .4rem;
	position: relative;
	transition: background .2s,border .2s,box-shadow .2s,color .2s;
	width: 100%;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
	background-color: #cf7f45;
}

.ant-steps-label-vertical .ant-steps-item-title {
	font-weight: 500;
}

.modal-title {
	font-size: 1.1rem;
}

.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot, 
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
	left: 0px;
	top: 1px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	font-size: 17px;
}

.ant-spin-dot-item {
	background-color: #e78035;
}

.form-group {
	margin-bottom: 13px;
}

.btn-primary.disabled, 
.btn-primary:disabled {
	cursor: not-allowed;
}

.bold {
	font-weight: bold;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgb(173 173 173 / 50%);
}

.ant-message {
	z-index: 1090;
}

.line-through {
	text-decoration: line-through;
}

.items-list.ant-list-vertical .ant-list-item-meta-title {
	margin-bottom: 0px;
}

.items-list.ant-list-vertical .ant-list-item-meta {
	margin-bottom: 5px;
}

.items-list.ant-list-vertical .ant-list-item-action {
	margin-top: 0px;
}

.inline-block {
	display: inline-block;
}

.ant-table {
	font-size: 15px;
}

@media screen and (max-width: 576px){
	.ant-list-vertical .ant-list-item {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.ant-list-vertical .ant-list-item-extra {
		margin: 20px 0px 0px;
		width: 100%;
	}

	.ant-list-vertical .ant-list-item-extra .text-right { 
		text-align: left!important;
	}
}


@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}

.ant-pagination-item-active,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-open {
	border-color: #cf7f45;
}

.card-header {
	background-color: #fff;
}

.ant-rate {
	font-size: 35px;
}

p {
	line-height: normal;
}

.hint-text { 
	color: #777 !important;
}

.hide {
	display: none;
}

.ant-picker-input > input {
	font-size: 16px;
}

.search-top-bar {
	width: '100%';
	min-width: 350px;
	max-width: 500px;
	margin-bottom: 10px;
}

label.form-label {
	margin-bottom: 5px;
	margin-top: 5px;
}

.form-control {
	margin-bottom: 5px;
}

button.btn-inputgroup {
	height: 36px;
}

.ant-popover {
	z-index: 1075;
}

.input-group-text {
	font-size: 15px;
	padding: 0px 10px;
	height: 36px;
	font-weight: 600;
	color: #3b4351;
	background-color: #e8e8e8;
	border-radius: 0px;
}

.input-group {
	margin-bottom: 10px;
}

form .btn.btn-primary {
	margin-top: 10px;
}

th.ant-descriptions-item-label {
	font-weight: 600;
	text-align: right;
}

.ant-result-title {
	font-size: 17px;
	line-height: 1.2;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #e78035;
}
.ant-tabs-tab:hover {
	color: #cf7f45;
}

.ant-tabs-content-holder {
	border: 1px solid #f0f0f0;
	padding-right: 10px;
	padding-bottom: 10px;
}

.labelBold .ant-descriptions-item-label {
	font-weight: bold;
}

.card.simple {
	border: 0;
	padding: 0;
	box-shadow: none;
	margin-bottom: 10px;
}

.card.simple .card-header {
	padding: 0px;
}

.btn.btn-sm {
	font-size: 13px;
}

.bg-primary-light {
	background-color: #f3be97;
}

.regular {
	font-weight: 400;
}

#certificateDiv {
	background-color: #f5f5f5;
	width: 216mm;
	min-height: 279mm;
}

@media screen {
	.printFooter {
	  	display: none;
	}
}
 @media print {
	.printFooter {
	  position: fixed;
	  bottom: 0;
	  font-size: 10;
	  margin-top: 20;
	}
	.main-content {
		margin: 0 !important;
		padding: 0 !important;
		top: 0 !important;
  	}
 }

.input-group .form-control {
	margin-bottom: 0px;
}

.bg-light {
	background-color: #f4f4f4 !important;
}

table.all_borders {
    border:1px solid black;
    border-collapse: collapse;
}
table.all_borders tr {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

table.all_borders td {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}

table.all_borders th {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}

table.table.compact_table td {
	padding: 3px 3px;
}

@media print {
	.print-page-break {
		page-break-before: always;
		margin-top: 50px;
	}
}

table.dewar_template_table tr td {
	border: 1px solid black;
	padding: 4px 3px;
	vertical-align: middle;
	border-left: 1px solid #000;
    border-right: 1px solid #000;
}

table.dewar_template_table {
	margin-bottom: 3px;
    border:1px solid black;
    border-collapse: collapse;
}

table.dewar_template_table tr td.title {
	background-color: #f6c6ac;
	font-weight: bold;
	text-align: center;
}

table.dewar_template_table tr td.subtitle {
	background-color: #fae2d5;
	font-weight: bold;
	text-align: center;
}

table.dewar_template_table tr td.subtitle2 {
	background-color: #d8d8d8;
	font-weight: bold;
}


table.dewar_template_table tr {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

table.dewar_template_table th {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}

.text-left {
	text-align: left!important;
}

.text-right {
	text-align: right!important;
}

.text-blue {
	color: blue;
}